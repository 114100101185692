import logo from "./assets/img/logo.png";
import img1 from "./assets/img/rick-and-morty-content.gif";
import img3 from "./assets/img/summer-beth.gif";
import img2 from "./assets/img/drinking-wine-nervous.gif";
import ab1 from "./assets/img/angry-mad.gif";
import ab2 from "./assets/img/rick-and-morty-rick-sanchez-puking-rainbows-animated-custom-cursor-(1).gif";
import "./App.css";

function App() {
  return (
    <div className="overflow-hidden relative App bg-[url('./assets/img/bg.png')] bg-no-repeat bg-center min-h-screen w-full">
      <div className="max-w-md mx-auto py-12 lg:py-16">
        <img className="w-[80%] mx-auto" src={logo} alt="logo" />
      </div>
      <p className="text-center px-3">
        Smart contract:{" "}
        <span className="text-primary">
          0xA6ee16d2EA62Eeca70515C7e66597a45287DBeb1
        </span>
      </p>
      <div className="mx-5 my-8 lg:my-10">
        <div className="relative z-10 border-[1px] border-primary max-w-5xl mx-auto p-8 rounded-lg">
          <div className="flex flex-col-reverse gap-16 xl:flex-row xl:gap-5">
            <div className="w-full xl:max-w-xs">
              <h1 className="text-primary text-4xl">Beth said:</h1>
              <p className="my-7 text-xl">
                " Soaring graph is your power. It makes you strong{" "}
                <span className="text-primary">like a boob</span> "
              </p>
              <div className="pt-7 flex flex-col gap-5">
                <a href="#" target="_blank" rel="noopener noreferrer">
                  Buy now
                </a>
                <a href="#" target="_blank" rel="noopener noreferrer">
                  COntract
                </a>
                <a href="#" target="_blank" rel="noopener noreferrer">
                  view Chart
                </a>
                <a
                  href="https://t.me/BethClubETH"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Telegram
                </a>
                <a
                  href="https://twitter.com/BethClubETH"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Twitter
                </a>
              </div>
            </div>
            <div className="w-full flex flex-col justify-stretch">
              <div className="w-full xl:h-full">
                <img
                  className="w-full h-full rounded-lg object-cover"
                  src={img1}
                  alt=""
                />
              </div>
              <div className="grid grid-cols-1 gap-5 mt-6 lg:grid-cols-2 lg:h-[222px]">
                <img
                  className="h-[222px] w-full object-cover rounded-lg"
                  src={img2}
                  alt=""
                />
                <img
                  className="h-[222px] w-full object-cover rounded-lg"
                  src={img3}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="hidden absolute z-10 right-[-50px] bottom-[-20px] h-[152px] w-[152px] md:block xl:bottom-[-50px]">
            <img className="h-full w-full" src={ab2} alt="" />
          </div>
          <div className="hidden absolute z-10 bottom-[40%] left-[-250px] h-[186px] w-[186px] md:block lg:h-[232px] lg:w-[232px]">
            <img
              className="h-[186px] w-[186px] rounded-full rotate-[-15deg] lg:h-[232px] lg:w-[232px]"
              src={ab1}
              alt=""
            />
          </div>
        </div>
      </div>

      <p className="text-center pb-8 normal-case px-3">
        Contact: <span className="text-primary">devbethclub@gmail.com</span>
      </p>

      <div className="hidden bg-1 lg:block"></div>
    </div>
  );
}

export default App;
